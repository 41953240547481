import Transitions from '../layouts/Transitions'

import { Bloks } from '../bloks'

const Page = ({ blok, ...props }) => {
  const { body } = blok

  const content = body && body.map((item) => <Bloks blok={item} hello="ndskj" {...props} key={item._uid} />)

  return (
    <Transitions transition="text" as="section" sx={{ variant: 'layout.container' }}>
      {content}
    </Transitions>
  )
}

export default Page
