import { Link } from 'gatsby'
import { useState, useMemo } from 'react'
import { motion } from 'framer-motion'
import { useThemeUI } from 'theme-ui'
import { useResponsiveValue } from '@theme-ui/match-media'
import { Container, Heading } from '@monobits/components'
import { useLocomotiveScroll } from '@monobits/locomotive-scroll'
import { useRefs } from '@monobits/gsap'
import { gsap } from 'gsap'

import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'

import { CloudImage, CloudAssets } from '@boiler/cloudinary'
import Obfuscate from 'react-obfuscate'

import Transitions from '../layouts/Transitions'
import { Rte, Icons, Button } from '../atoms'
import { Collapsibles, StickyIndicator } from '../components'
import { useBetterSticky } from '../hooks'

SwiperCore.use([Navigation])

const Product = ({ blok, locales: { locales = {} } = {}, path, settings }) => {
  const { title, labels, content, collapsibles, price, width: x, height: y, assets, _uid } = blok ?? {}
  const fullTitle = [title, ...labels.list.map((label) => label.text)].join('. ') + '.'

  let refs = useRefs(['prev', 'next', 'indicator', 'init'])

  const { ref, top, attributes } = useBetterSticky('#body', '#content')
  const [loaded, setLoaded] = useState(0)

  const { theme } = useThemeUI() ?? {}
  const { scrollDuration: duration } = theme?.config?.locomotive?.extends ?? {}
  const { lazyFade: variants } = theme?.transitions ?? {}

  const { scroll } = useLocomotiveScroll()

  const ready = loaded >= Math.min(2, assets.length) ? 'animate' : 'initial'

  const scrollTo = (id) => scroll.scrollTo(`[data-id="${id}"]`.toString(), { duration, offset: [-top, top] })

  const watch = [scroll, assets, top]
  const medias = useMemo(
    () =>
      !!assets.length && (
        <CloudAssets
          assets={assets}
          image={(_uid, i) => ({
            'data-id': _uid,
            options: { lazyload: !!(i + 1 < 2) ? false : null, placeholder: false },
          })}
          video={(_uid) => ({ 'data-id': _uid })}
          onLoad={() => setLoaded((prev) => prev + 1)}
        />
      ),
    [...watch] // eslint-disable-line
  )
  const nav = useMemo(
    () =>
      !!assets.length && (
        <CloudAssets
          as="button"
          onClick={(_uid) => scrollTo(_uid)}
          assets={assets}
          image={(_uid) => ({
            modifiers: (img, tr) => img.addTransformation(tr.optimize()).addTransformation(tr.optimizeScale(400)),
            options: {
              lazyload: false,
              responsive: [100, [0, 400, 100]],
              placeholder: false,
            },
          })}
        />
      ),
    [...watch] // eslint-disable-line
  )
  const slides = useMemo(
    () =>
      assets.map(
        ({ component, image, _uid }, i) =>
          ({
            image: (
              <SwiperSlide key={i + _uid}>
                <CloudImage
                  url={image?.filename}
                  alt={image?.alt}
                  sx={{ objectPosition: 'center' }}
                  fit="contain"
                  options={{ lazyload: false, responsive: [300, [70]] }}
                  icons={Icons}
                  onLoad={() => setLoaded((prev) => prev + 1)}
                />
              </SwiperSlide>
            ),
          }[component])
      ),
    [...watch] // eslint-disable-line
  )

  const slider = (
    <>
      <div sx={{ variant: 'project.medias' }}>
        <Swiper
          loop={true}
          navigation={{
            nextEl: refs.next.current,
            prevEl: refs.prev.current,
          }}
          onSlideChange={(swiper) => {
            if (!!refs.init.current) {
              gsap.to(refs.indicator.current, {
                scaleX: (swiper.realIndex + 1) / assets.length,
                duration: 0.2,
                overwrite: 'auto',
                ease: 'power2.out',
              })
            }
            refs.init.current = true
          }}
        >
          {!!assets.length && slides}
        </Swiper>

        <button ref={refs.next} sx={{ variant: 'project.navigation' }} data-nav="next">
          <Icons name="normal-arrow-right" />
        </button>
        <button ref={refs.prev} sx={{ variant: 'project.navigation' }} data-nav="prev">
          <Icons name="normal-arrow-left" />
        </button>
      </div>
      <div
        ref={refs.indicator}
        data-slider-bar
        sx={{
          variant: 'slider.bar',
          width: '100%',
          mx: 0,
          '--initial-scale': assets?.length && 1 / assets?.length,
        }}
      />
    </>
  )

  const gallery = useResponsiveValue([slider, slider, medias])

  const mailing = (key) => ({
    email: settings?.shop_email || 'dev@monolith.agency',
    children: locales[key],
    headers: {
      subject: `${locales[key]} - ${title}`,
    },
    target: '_blank',
  })

  return (
    <Transitions>
      <Container variant="box" sx={{ variant: 'product' }}>
        <motion.div
          variants={variants}
          initial="initial"
          animate={ready}
          sx={{ variant: 'product.container' }}
          id="body"
        >
          <StickyIndicator className="-show-desktop-block" target="#gallery" offset={[-top, top]} children={nav} />

          <aside id="gallery" sx={{ variant: 'product.images' }}>
            {gallery}
          </aside>

          <section ref={ref} sx={{ variant: 'product.content' }} {...attributes} id="content">
            <div>
              <Heading variant="emphasized" sx={{ pb: [3, null, 2] }}>
                {fullTitle}
              </Heading>

              <Heading variant="emphasized" sx={{ pb: [3, null, 2] }}>
                <span>{price}&#8239;$</span>&nbsp;—&nbsp;
                <span>
                  {x}" x {y}"
                </span>
              </Heading>

              <Button
                as={Obfuscate}
                {...mailing('purchase')}
                variant="secondary"
                sx={{ mr: 1, mb: ['var(--padding-s)', null, 0], width: ['100%', null, 'fit-content'] }}
              />
              <Button
                as={Obfuscate}
                {...mailing('artwork-inquiry')}
                sx={{ mr: 1, width: ['100%', null, 'fit-content'] }}
              />

              <Rte content={content} sx={{ pt: [3, null, 2] }} />

              <Collapsibles
                blok={{ items: collapsibles, _uid }}
                sx={{
                  pt: [3, null, 2],
                  '[data-selected]': {
                    justifyItems: ['center', null, 'start'],
                    justifyContent: ['center', null, 'start'],
                  },
                  button: {
                    whiteSpace: 'nowrap',
                    '>div': {
                      width: ['1em', null, 'unset'],
                    },
                  },
                }}
                icon={<Icons name="plus" />}
                auto
              />
            </div>
          </section>
        </motion.div>

        <Button
          as={Link}
          to={'/' + path?.split('/')?.[1]}
          variant="secondary"
          icon="arrow-left"
          sx={{ mt: 3, alignSelf: 'flex-end', width: ['100%', null, 'fit-content'] }}
        >
          {locales['back-to-shop']}
        </Button>
      </Container>
    </Transitions>
  )
}

export default Product
