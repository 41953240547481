import { Container } from '@monobits/components'

import { Bloks } from '../bloks'
import Home from './Home'
import Page from './Page'
import Project from './Project'
import Projects from './Projects'
import Product from './Product'
import Products from './Products'
import Articles from './Articles'

const Template = ({ component, ...props }) => {
  const Component =
    {
      global: Bloks,
      home: Home,
      page: Page,
      project: Project,
      projects: Projects,
      articles: Articles,
      product: Product,
      products: Products,
    }[component] || Bloks

  if (component === 'global') {
    return (
      <Container variant="box" data-inview="false">
        <Component {...props} />
      </Container>
    )
  }

  return <Component {...props} />
}

export default Template
