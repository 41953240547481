import { useCallback } from 'react'
import { useConditionalEffect, useResizeObserver } from '@react-hookz/web'
import { getDocument } from 'ssr-window'
import { useRefs } from '@monobits/gsap'
import { gsap } from 'gsap'
import { Draggable } from 'gsap/Draggable'
import { InertiaPlugin } from 'gsap/InertiaPlugin'

import { StyledSlider, StyledCell } from './Slider.styled'

const document = getDocument()

const BASE = {
  inertia: true,
  dragClickables: true,
  overshootTolerance: 0,
  maxDuration: 0.35,
}

/**
 * This is the Slider component
 */
const Slider = ({
  children,
  sx,
  drag = {},
  dynamic = false,
  component: [Assets, assetsProps],
  image: imageProps = () => {},
  video: videoProps = () => {},
  onIndexChange = () => {},
  assets,
  bar = true,
  ...props
}) => {
  let refs = useRefs(['container', 'cells', 'indicator'])

  const dragConfig = typeof drag === 'function' ? drag({ gsap, refs }) : drag
  const options = { ...BASE, ...dragConfig }

  const span = assets.length <= 2
  const renderAssets = !span ? assets : assets.length === 1 ? [...assets, ...assets, ...assets] : [...assets, ...assets]

  const addRef = useCallback((el) => {
    const ref = refs.cells
    const items = ref.current == null ? (ref.current = []) : ref.current
    if (el && !items.includes(el)) items.push(el)
  }, [])

  const init = () => {
    const container = refs.container.current
    const items = refs.cells
    const cells = items.current
    let cellsWidth, cellWidth, cellsHeight, cellsLength

    if (cells.length > 0) {
      cellsLength = cells.length
      cellsWidth = cells[0].clientWidth * cellsLength
      cellWidth = cells[0].clientWidth
      cellsHeight = cells[0].clientHeight

      gsap.set(cells, { xPercent: (i) => i * 100, left: 0, visibility: 'visible' })

      if (dynamic) gsap.set(container, { height: cellsHeight })
    }

    const progressWrap = gsap.utils.wrap(0, 1)
    const wrap = gsap.utils.wrap(-100, (cellsLength - 1) * 100)
    const proxy = document.createElement('div')
    const transform = gsap.getProperty(proxy)

    const animation = gsap.to(cells, {
      duration: 1,
      ease: 'none',
      paused: true,
      repeat: -1,
      xPercent: '+=' + cellsLength * 100,
      modifiers: {
        xPercent: wrap,
      },
    })

    const indicator =
      bar &&
      gsap.to(refs.indicator.current, {
        duration: 1,
        ease: 'none',
        paused: true,
        repeat: -1,
        opacity: '+=' + cellWidth,
        modifiers: {
          opacity: (x) => gsap.utils.clamp(0.001, 0.5, x * 0.008),
        },
      })

    Draggable.create(proxy, {
      type: 'x',
      trigger: '[data-cell]',
      onDrag: updateProgress,
      onThrowUpdate: updateProgress,
      snap: { x: (x) => Math.round(x / cellWidth) * cellWidth },
      bounds: { left: -cellWidth },
      ...options,
    })[0]

    const tracker = InertiaPlugin.track(proxy, 'x')[0]

    function getIndex() {
      let value
      const forward = 1 - (animation?.progress() || 0)
      if (forward === 1) {
        value = 0
      } else {
        value = Math.round(cellsLength * forward)
      }
      return gsap.utils.clamp(0, cellsLength - 1, value)
    }

    function updateProgress(e) {
      if (animation) {
        const progress = progressWrap(transform('x') / cellsWidth)
        animation.progress(progress)

        if (dynamic) gsap.set(container, { height: gsap.getProperty(cells[getIndex()], 'height') })
        if (onIndexChange != null) onIndexChange(getIndex(), { gsap, refs, tracker })

        if (bar) {
          indicator.progress(progress)
          gsap.set(refs.indicator.current, {
            scaleX: 1 - progress,
          })
        }
      }
    }
  }

  useConditionalEffect(
    () => {
      gsap.registerPlugin(Draggable, InertiaPlugin)
      init()
    },
    [],
    [refs.cells != null]
  )

  useResizeObserver(refs.container.current, () => {
    init()
  })

  return (
    <>
      <StyledSlider ref={refs.container} sx={{ variant: 'slider', ...sx }} data-slider {...props}>
        <Assets
          ref={addRef}
          assets={renderAssets}
          data-cell
          data-dynamic={!!dynamic}
          as={StyledCell}
          {...assetsProps}
        />
      </StyledSlider>
      {bar && <div ref={refs.indicator} data-slider-bar sx={{ variant: 'slider.bar' }} />}
    </>
  )
}

export default Slider
