import { Link } from 'gatsby'
import { useMemo, useState } from 'react'
import { useThemeUI, Box } from 'theme-ui'
import { motion } from 'framer-motion'
import { Container, Heading } from '@monobits/components'
import { useLocomotiveScroll } from '@monobits/locomotive-scroll'
import { useTween, useRefs } from '@monobits/gsap'
import { CloudImage } from '@boiler/cloudinary'
import { kebabCase } from 'lodash'
import { gsap } from 'gsap'

import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'

import { Bloks } from '../bloks'
import { Button, Icons } from '../atoms'
import Transitions from '../layouts/Transitions'

const string = {
  info: 'project-information',
  visual: 'project-visuals',
  projects: 'back-to-all-projects',
}

const duration = [0.35, 0.65]

SwiperCore.use([Navigation])

const Project = ({ blok, locales: { locales = {} } = {}, path, ...props }) => {
  const { body, title, year, images } = blok ?? {}

  const preload = !!images.filter((i) => i.component === 'image' || i.component === 'video').length

  let refs = useRefs(['index', 'body', 'button', 'prev', 'next', 'init'])
  const [overlay, setOverlay] = useState(true)
  const [loaded, setLoaded] = useState(preload ? 0 : 10)

  const { lazy: variants } = useThemeUI().theme.transitions ?? {}
  const { scroll } = useLocomotiveScroll()

  const [tween, element] = useTween(
    ({ gsap, node, $ }) =>
      gsap
        .timeline({ paused: true, ease: 'expo.out' })
        .fromTo(
          node,
          { autoAlpha: 1, clearProps: 'pointer-events' },
          { autoAlpha: 0, pointerEvents: 'none', duration: duration[0] }
        )
        .add('middle')
        .fromTo(
          [$(refs.body), $(refs.button)],
          { autoAlpha: 0, y: '3vmax', skewX: -0.5 },
          { autoAlpha: 1, y: 0, skewX: 0, duration: duration[1] },
          'middle+=0.25'
        ),
    [Object.values(refs).every((i) => i != null)]
  )

  const id = kebabCase(title).toString()

  const ready = loaded >= 2 ? 'animate' : 'initial'

  const slides = useMemo(
    () =>
      images.map(
        ({ component, image, height, width, _uid }, i) =>
          ({
            image: (
              <SwiperSlide key={i + _uid}>
                <CloudImage
                  url={image?.filename}
                  alt={image?.alt}
                  sx={{ objectPosition: 'center' }}
                  fit="contain"
                  options={{ lazyload: false, responsive: [300, [70]] }}
                  icons={Icons}
                  onLoad={() => setLoaded((prev) => prev + 1)}
                />
              </SwiperSlide>
            ),
            placeholder: (
              <SwiperSlide key={i + _uid}>
                <Box sx={{ size: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div
                    data-aspect-ratio
                    sx={{
                      '--aspect-ratio': width && height ? width / height : null,
                      height: '100%',
                      maxWidth: '96%',
                      display: 'flex',
                    }}
                  >
                    <Icons preserveAspectRatio="none" name="placeholder" />
                  </div>
                </Box>
              </SwiperSlide>
            ),
          }[component])
      ),
    [images]
  )

  const slider = (
    <>
      <motion.div sx={{ size: '100%', display: 'flex' }} variants={variants('skewX')} initial="initial" animate={ready}>
        <Swiper
          loop={true}
          navigation={{
            nextEl: refs.next.current,
            prevEl: refs.prev.current,
          }}
          onSlideChange={(swiper) => {
            !!refs.init.current && gsap.set(refs.index.current, { text: `${swiper.realIndex + 1}/${images.length}` })
            refs.init.current = true
          }}
        >
          {!!images.length && slides}
        </Swiper>
      </motion.div>
      <button ref={refs.next} sx={{ variant: 'project.navigation' }} data-nav="next">
        <Icons name="normal-arrow-right" />
      </button>
      <button ref={refs.prev} sx={{ variant: 'project.navigation' }} data-nav="prev">
        <Icons name="normal-arrow-left" />
      </button>
    </>
  )

  return (
    <Transitions>
      <Container
        ref={element.ref}
        data-scroll
        data-scroll-sticky
        data-scroll-target={'#' + id}
        sx={{ variant: 'project' }}
        variant="box"
        aria-hidden={!!!overlay}
      >
        <div sx={{ variant: 'project.head' }}>
          <Heading
            sx={{ gridColumn: ['1/2', null, 'unset'], gridRow: ['2/3', null, 'unset'] }}
            variant="caption"
            children={year}
          />
          <Heading
            sx={{ gridColumn: ['2/-1', null, 'unset'], gridRow: ['2/3', null, 'unset'] }}
            variant="caption"
            children={title}
          />
          <Heading
            sx={{ gridColumn: ['2/3', null, 'unset'], gridRow: ['1/2', null, 'unset'] }}
            variant="caption"
            ref={refs.index}
            children={`1/${images.length}`}
          />
        </div>

        <aside sx={{ variant: 'project.medias' }}>{slider}</aside>

        <div sx={{ variant: 'project.foot' }}>
          <Button variant="secondary" icon="arrow-left" as={Link} to={'/' + path?.split('/')?.[1]}>
            {locales[string.projects]}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setOverlay(false)
              setScroll(scroll)
              tween.timeScale(1).play()
            }}
          >
            {locales[string.info]}
          </Button>
        </div>
      </Container>

      <Container id={id} aria-hidden={!!overlay} variant="box" sx={{ variant: 'project.text' }}>
        <div ref={refs.body} sx={{ transformOrigin: 'top right' }}>
          {body && body.map((item) => <Bloks blok={item} {...props} key={item._uid} />)}
        </div>
        <div
          ref={refs.button}
          data-scroll
          data-scroll-sticky
          data-scroll-target={'#' + id}
          sx={{ variant: 'project.switch' }}
        >
          <Button
            variant="primary"
            icon="arrow-left"
            onClick={() => {
              setOverlay(true)
              tween.timeScale(1.5).reverse()
            }}
          >
            {locales[string.visual]}
          </Button>
        </div>
      </Container>
    </Transitions>
  )
}

function setScroll(scroll) {
  const { isMobile, isTablet } = scroll.scroll

  if (isMobile || isTablet) {
    window.scrollTo(0, 'auto')
  } else {
    scroll.scrollTo(0, { duration: 0, disableLerp: true })
  }
}

export default Project
