import styled from '@emotion/styled'
import { css } from 'theme-ui'

export const x = {
  width: 'var(--cell-width, 100%)',
}

export const StyledSlider = styled.div(
  css({
    // overflow: 'hidden',
    position: 'relative',
    width: '100%',
    willChange: 'transform',
  })
)

export const StyledCell = styled.div(
  css({
    position: 'absolute!important',
    top: 0,

    width: x.width,
    '&[data-dynamic=false]': {
      height: '100%',
    },

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&:not(:first-of-type)': {
      left: '200vw',
      visibility: 'hidden',
    },
  })
)
