import { useRef, useEffect } from 'react'
import { useSafeState } from '@react-hookz/web'
import { omit } from 'lodash'

import { parseContent } from '@boiler/utils'
import StoryblokClient from 'storyblok-js-client'

const Storyblok = new StoryblokClient({
  accessToken: process.env.GATSBY_STORYBLOK_TOKEN,
  cache: { clear: 'auto', type: 'memory' },
})

const reduceQuery = (data) => data.map(({ node }) => node)

const parseContents = (data) => data.map((node) => ({ ...omit(node, ['content']), ...parseContent(node.content) }))

const useDynamicPagination = (initialData = {}, { call = {}, limit = 1, totalPages = 1, location }) => {
  const initial = reduceQuery(initialData)
  const [datas, setDatas] = useSafeState(initial)

  let index = useRef(1)
  let cache = useRef(initial)

  async function loadPage(i) {
    await Storyblok.get(`cdn/stories`, {
      version: process.env.GATSBY_STORYBLOK_VERSION,
      per_page: limit,
      page: i ? i : index + 1,
      ...call,
    }).then(({ data }) => {
      if (i === 1) {
        data.stories && setDatas([data.stories])
      } else {
        data.stories && (cache.current = [...datas, ...data.stories])
      }
    })
  }

  const available = index.current < totalPages
  const increment = () => {
    if (available) {
      setDatas(cache.current)
      index.current = index.current + 1
    }
  }

  useEffect(() => {
    if (location?.search?.includes('_storyblok')) loadPage(1)
  }, []) // eslint-disable-line

  useEffect(() => {
    loadPage(index.current + 1)
  }, [datas]) // eslint-disable-line

  return {
    index,
    increment,
    available,
    data: parseContents(datas),
  }
}

export default useDynamicPagination
