import { Link } from 'gatsby'
import { useRef, useState, useMemo } from 'react'
import { useThemeUI } from 'theme-ui'
import { motion, AnimatePresence } from 'framer-motion'
import { useUpdateEffect, useConditionalEffect } from '@react-hookz/web'
import { useLocomotiveScroll } from '@monobits/locomotive-scroll'
import { Container } from '@monobits/components'
import { useDynamicPagination } from '@boiler/components'
import { kebabCase } from 'lodash'

import { useTransitionsEffect } from '../layouts/Transitions'
import { InlineProject, ImageCard } from '../components'
import { Button } from '../atoms'

const querify = (obj) =>
  '?' +
  Object.entries(obj)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')

const Projects = ({ blok, pagination, tags, source: { slug }, locales: { locales = {} } = {}, location }) => {
  const { default_view = 'list', default_tag = 'all' } = blok ?? {}
  const { data: initialData, limit, totalPages, childComponent } = pagination ?? {}

  const { scroll } = useLocomotiveScroll()
  const { data, increment, available } = useDynamicPagination(initialData, {
    limit,
    totalPages,
    call: {
      filter_query: { component: { in: childComponent } },
      starts_with: `${slug}/`,
    },
    location,
  })

  const variants = useThemeUI().theme.transitions ?? {}

  /**
   * Set query params after the state.
   * Prevents scrollTop before exit transition. */
  const params = new URLSearchParams(location.search)
  const [tag, setTag] = useState(params.get('tag'))
  const [view, setView] = useState(params.get('view'))
  const [hovering, setHovering] = useState(false)

  // Set states if no params are detected
  let injected = useRef(false)
  useConditionalEffect(
    () => {
      injected.current = true
      window.history.replaceState(
        {},
        null,
        location.pathname.replace(/\/$/, '') + querify({ tag: default_tag, view: default_view })
      )
      setTag(default_tag)
      setView(default_view)
    },
    undefined,
    [!!!location.search || location.search.includes('storyblok'), !injected.current]
  )

  // Handle scroll & history
  useConditionalEffect(
    () => {
      if ('history' in window) {
        window.history.replaceState({}, null, location.pathname.replace(/\/$/, '') + querify({ tag, view }))
      }
      const val = variants.duration[view] ?? []
      setTimeout(() => {
        if (window.innerWidth >= scroll.tablet.breakpoint) {
          scroll.scrollTo(0, { duration: 0, disableLerp: true })
        } else {
          window.scrollTo(0, 'auto')
        }
      }, (val[1] + val[2]) * 1000)
    },
    [tag, view],
    [scroll],
    undefined,
    useUpdateEffect
  )

  const transitions = { list: 'list', index: 'cards' }[view] || 'list'
  useTransitionsEffect((setTransition) => setTransition(transitions), [view])

  const categories = tags.sort((a, b) => b.localeCompare(a)).map((i) => kebabCase(i))
  const toggleView = (string = view) => (string === 'index' ? 'list' : 'index')
  const selected = (string) => (tag === string ? 'primaryFixed' : 'secondary')
  const filterTag = (obj) =>
    obj.filter((i) => (tag === 'all' ? true : i.tag_list.map((i) => kebabCase(i)).includes(tag)))
  const filterImages = (obj) => obj.filter(({ images }) => !!images.length)

  // Shuffle the projects images
  const randomized = useMemo(
    () =>
      shuffle(
        filterImages(data).reduce(
          (acc, { title, images, full_slug, year, tag_list, uuid }) => [
            ...acc,
            ...images.map((each, i) => {
              return {
                uuid: uuid + i,
                index: i + 1,
                length: images.length,
                image: each.image,
                width: +each.width,
                height: +each.height,
                full_slug,
                tag_list,
                title,
                year,
              }
            }),
          ],
          []
        )
      ),
    [data.length] // eslint-disable-line
  )

  const views = {
    list: (
      <AnimatePresence exitBeforeEnter>
        <motion.section
          key={'list' + tag + view}
          sx={{ variant: 'layout.section.list' }}
          variants={variants.list}
          {...variants.variantKeys}
        >
          <div data-hovering={!!hovering}>
            {filterTag(filterImages(data)).map(({ title, uuid, full_slug, images }, i) => (
              <InlineProject
                key={uuid}
                title={title}
                as={Link}
                to={'/' + full_slug}
                index={i}
                image={{
                  alt: images[0].image?.alt,
                  url: images[0].image?.filename,
                }}
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
              />
            ))}
          </div>
        </motion.section>
      </AnimatePresence>
    ),
    index: (
      <AnimatePresence exitBeforeEnter>
        <motion.section
          key={'index' + tag + view}
          sx={{ variant: 'layout.section.index' }}
          variants={variants.cards}
          {...variants.variantKeys}
        >
          {filterTag(randomized.filter((i) => i.image != null)).map(
            ({ title, uuid, full_slug, image, width, height, year, index, length }, i) => (
              <ImageCard
                key={uuid}
                as={Link}
                to={'/' + full_slug}
                title={title}
                index={[index, length]}
                year={year}
                image={{
                  ratio: +width ? +width / +height : null,
                  alt: image?.alt,
                  url: image?.filename,
                  options: { lazyload: !!(i + 1 < 8) ? false : null, placeholder: false },
                }}
              />
            )
          )}
        </motion.section>
      </AnimatePresence>
    ),
  }

  return (
    <>
      <Container variant="box" sx={{ display: 'flex', flexDirection: 'column' }}>
        {views[view]}

        {available && (
          <Button icon="plus" sx={{ alignSelf: 'flex-end', mb: [6, 5] }} onClick={() => increment()}>
            {locales['load-more']}
          </Button>
        )}

        <div data-scroll data-scroll-sticky data-scroll-target="main" sx={{ variant: 'projects.fixed' }}>
          <AnimatePresence exitBeforeEnter>
            {(!!view || !!tag) && (
              <motion.nav variants={variants.tools} {...variants.variantKeys}>
                {!!view && (
                  <Button
                    data-view
                    icon={{ list: 'list', index: 'grid' }[toggleView()]}
                    onClick={() => setView((prev) => toggleView(prev))}
                    variant="secondary"
                  >
                    {locales[toggleView()]}
                  </Button>
                )}
                {!!tag &&
                  categories.map((string, i) => (
                    <Button
                      key={string + i}
                      icon="bullet"
                      variant={selected(string)}
                      onClick={() => {
                        setTag((prev) => (prev === string ? 'all' : string))
                      }}
                    >
                      {string}
                    </Button>
                  ))}
              </motion.nav>
            )}
          </AnimatePresence>
        </div>
      </Container>
    </>
  )
}

function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i)
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}

export default Projects
