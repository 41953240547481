import { Link } from 'gatsby'
import { Section } from '@monobits/components'
import { useDynamicPagination } from '@boiler/components'

import { Button } from '../atoms'
import { ImageCard } from '../components'
import Transitions from '../layouts/Transitions'

const Products = ({ pagination, source: { slug }, locales: { locales = {} } = {}, location }) => {
  const { data: initialData, limit, totalPages, childComponent } = pagination ?? {}

  const { data, increment, available } = useDynamicPagination(initialData, {
    limit,
    totalPages,
    call: {
      sort_by: 'content.year:desc',
      filter_query: { component: { in: childComponent } },
      starts_with: `${slug}/`,
    },
    location,
  })

  return (
    <Transitions transition="cards" as="div" sx={{ variant: 'layout.container.box' }}>
      <Section variant="cards">
        {data
          .filter(({ assets }) => !!assets.length)
          .map(({ title, labels, price, assets, uuid, full_slug }, i) => {
            const firstImage = assets.filter((obj) => obj.component === 'image')?.[0]
            const fullTitle = [title, ...labels.list.map((label) => label.text)].join('. ') + '.'

            return (
              <ImageCard
                key={uuid}
                as={Link}
                to={'/' + full_slug}
                title={fullTitle}
                price={price}
                index={[i + 1]}
                image={{
                  ratio: 3 / 4,
                  alt: firstImage.image?.alt,
                  url: firstImage.image.filename,
                  options: { lazyload: !!(i + 1 < 4) ? false : null, placeholder: false },
                }}
              />
            )
          })}
      </Section>
      {available && (
        <Button icon="plus" sx={{ alignSelf: 'flex-end', mb: [6, 5] }} onClick={() => increment()}>
          {locales['load-more']}
        </Button>
      )}
    </Transitions>
  )
}

export default Products
