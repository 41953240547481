import { Section } from '@monobits/components'
import { useDynamicPagination } from '@boiler/components'

import { ScrollTo, Button } from '../atoms'
import { MediaCard } from '../components'
import Transitions from '../layouts/Transitions'

const Articles = ({ pagination, source: { slug }, locales: { locales = {} } = {}, location }) => {
  const { data: initialData, limit, totalPages, childComponent } = pagination ?? {}

  const { data, increment, available } = useDynamicPagination(initialData, {
    limit,
    totalPages,
    call: {
      sort_by: 'content.date:desc',
      filter_query: { component: { in: childComponent } },
      starts_with: `${slug}/`,
    },
    location,
  })

  return (
    <Transitions transition="cards" as="div" sx={{ variant: 'layout.container.box' }}>
      <Section variant="cards">
        {data.map(({ title, description, date, link, image, source, uuid }, i) => {
          return (
            <MediaCard
              key={uuid}
              even={i % 2}
              title={title}
              source={source}
              link={link}
              date={{
                date: date,
                locale: 'en-CA',
                format: { month: 'long', day: 'numeric', year: 'numeric' },
              }}
              image={{
                ratio: '1/1',
                options: { lazyload: !!(i + 1 < 4) ? false : null },
                url: image?.filename,
                alt: image?.alt,
              }}
            >
              {description}
            </MediaCard>
          )
        })}
      </Section>

      {available ? (
        <Button icon="plus" onClick={() => increment()}>
          {locales['load-more']}
        </Button>
      ) : (
        <Button variant="secondary" icon="arrow-top" as={ScrollTo} top>
          {locales['back-to-top']}
        </Button>
      )}
    </Transitions>
  )
}

export default Articles
